import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Routes, BrowserRouter as Router, Route
} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import img1 from "./photos/test.jpg";
import img2 from './photos/test14.jpg';
import img3 from './photos/test15.jpg';
import img4 from './photos/test16.jpg';
import img5 from './photos/test17.jpg';
import img6 from './photos/test18.jpg';
import img7 from './photos/test19.jpg';
import img8 from './photos/test20.jpg';
import img9 from './photos/test21.jpg';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
      <Routes>
        <Route path="/1" element={<App  name='aaa' imgs={[img1, img2, img3, img4, img5, img6, img7, img8, img9 ]}/>} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
