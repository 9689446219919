import { Carousel, Divider, Input, message } from "antd";
import YoutubeEmbed from "./YoutubeEmbed";
import "./App.css";
import { CheckCircleOutlined, FacebookOutlined, InstagramOutlined, PhoneOutlined } from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";

function App({ name, imgs }) {
  const [inputValue, setInputValue] = useState();

  const contentStyle = {
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  
  const handelPhone = () =>{
    setInputValue(+374);
  }

  const submit =  async ()=>{
     await axios.post('https://shopserver-a5t8.onrender.com/sendSMS',{ phone: inputValue, type: 'Դարակաշար-չորանոց-կախիչ 3-1ում'  }).then(()=>{
         message.success('Պատվերը հաստատված է, մեր աշխատակիցը կկապվնեն Ձեզ հետ Շնորհակալություն')
     });
  }
  return (
    <div className="App">
      <div className="header"><span>Դարակաշար-չորանոց-կախիչ 3-1ում</span></div>
       <Carousel  autoplay>
        {imgs.map((img) => {
          return (
            <div className="carusel">
              <img className="caruselImg" width="100%"  height="400px" style={contentStyle} src={img} alt="Loading" />
            </div>
          );
        })}
      </Carousel>
      <div className="price">
        <span>15.900 Դրամ</span>
      </div>
      <div className="context">
        <span className="title">Ապրանքատեսակի նկարագրություն</span>
        <span className="content">
         <ul>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> Անիվներով հեշտ տեղափոխվող</li>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> 3 հարկ, 6 տեղ, 14 կախիչի տեղ</li>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> Ամուր մետաղական կոնստրուկցիա</li>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> Հեշտ հավաքվող</li>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> Հարմար է լվացքը չորացնելու համար</li>
          <li><CheckCircleOutlined style={{color: "#5F4785", fontSize: '20px'}} /> Չափսեր -  75-126 x 64 x 170 սմ</li>
         </ul>
        </span>
      </div>
      <YoutubeEmbed embedId="uJxz3tm1W2w" />
      <input onClick={handelPhone} value={inputValue} onChange={(e)=>setInputValue(e.target.value)} placeholder="Հեռախոսահամար" className="phone" type='number'/>
      <div className="submit">
      <button onClick={submit} >Պատվիրել</button>
      </div>
      <div className="icon">
        <div>
        <a href='https://www.instagram.com/unique_store_am/' className='instagram'>
          <InstagramOutlined href="https://www.instagram.com/unique_store_am/" style={{color: "#FFFFFF", fontSize: '25px'}}/>
        </a>
        </div>
        <div style={{marginLeft: '10px'}}>
        <a href='https://www.facebook.com/uniquestoream' className='instagram'>
          <FacebookOutlined href="https://www.facebook.com/uniquestoream" style={{color: "#FFFFFF", fontSize: '25px'}}/>
        </a>
        </div>
      </div>
    </div>
  );
}

export default App;
